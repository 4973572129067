import React from 'react';
import './Contenido.css';

function Contenido() {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    // Mapear índices de imágenes residenciales a sus títulos
    const residencialTitles = {
        1: 'Casa Rancho San Juan, Atizapán de Zaragoza Edo. Mex',
        7: 'Casa Hip, Valle de Bravo Edo. Mex',
        19: 'Casa Emerson, Cabo San Lucas B.C.S',
        27: 'Casa Cocoyoc, Huastepec Morelos',
        34: 'Casa Susana, Cabo San Lucas B.C.S',
        41: 'Casa Heath, Texas EUA',
        49: 'Casa La Rivera, San José del Cabo B.C.S',
        54: 'Casa Deane, San José del Cabo B.C.S',
        60: 'Casa Lety, La Paz B.C.S',
        65: 'Casa Chanty, Cabo San Lucas B.C.S',
        69: 'Casa Villa Gómez, Tehuacán Puebla',
        80: 'Casa Villa Esmeralda, Silao Gto',
        87: 'Casa Karen, Querétaro Qro',
        97: 'Cabaña Jerez, Mazamitla Jalisco',
        103: 'Casa Residencial Viñedos, Parras Coah',
        105: 'Casa Frank, Cabo San Lucas B.C.S',
        112: 'Casa Cataluña, Querétaro Qro'
    };
    const comercialTitles = {
        1: 'Plaza Zaragoza, Cabo San Lucas B.C.S',
        7: 'Restaurante PIIH, Guadalajara Jalisco',
        16: 'Plaza 20 de Noviembre, Cabo San Lucas B.C.S',
        21: 'Plaza Carolina, Guasave Sinaloa',
        26: 'Estética W Salon, La Paz B.C.S',
        28: 'Obras comerciales Varias, República Mexicana'
    };
    const corporativoTitles = {
        1: 'Casa Club P.C , Chapala Jalisco',
        11: 'Conjunto Ornelas, Cabo San Lucas B.C.S',
        17: 'Mariamar Suites, San José del Cabo B.C.S',
        21: 'Estudios Costa Azul, San José del Cabo B.C.S',
        26: 'La Gran Muralla, La Paz B.C.S'
    };
    // Función para generar imágenes residenciales
    const renderResidencialImages = () => {
        const images = [];
        for (let i = 1; i <= 125; i++) {
            const paddedNumber = String(i).padStart(2, '0'); // Asegurar formato "01", "02", etc.
            const title = residencialTitles[i]; // Obtener el título si existe
            images.push(
                <div key={i} className="proyecto-item">
                    {title && <p className="imagen-titulo">{title}</p>} {/* Mostrar título si existe */}
                    <img
                    key={i}
                    src={`./assets/residencial/${paddedNumber}.png`}
                    alt={`Residencial ${i}`}
                    className="proyecto-imagen"
                />
                </div>
            );
        }
        return images;
    };

    // Función para generar imágenes residenciales
    const renderComercialImages = () => {
        const images = [];
        for (let i = 1; i <= 28; i++) {
            const paddedNumber = String(i).padStart(2, '0'); // Asegurar formato "01", "02", etc.
            const title = comercialTitles[i]; // Obtener el título si existe
            images.push(
                <div key={i} className="proyecto-item">
                    {title && <p className="imagen-titulo">{title}</p>} {/* Mostrar título si existe */}
                <img
                    key={i}
                    src={`./assets/comercial/${paddedNumber}.png`}
                    alt={`Comercial ${i}`}
                    className="proyecto-imagen"
                />
                </div>
            );
        }
        return images;
    };

    // Función para generar imágenes residenciales
    const renderCorporativasImages = () => {
        const images = [];
        for (let i = 1; i <= 27; i++) {
            const paddedNumber = String(i).padStart(2, '0'); // Asegurar formato "01", "02", etc.
            const title = corporativoTitles[i]; // Obtener el título si existe
            images.push(
                <div key={i} className="proyecto-item">
                    {title && <p className="imagen-titulo">{title}</p>} {/* Mostrar título si existe */}
                <img
                    key={i}
                    src={`./assets/corporativas/${paddedNumber}.png`}
                    alt={`Corporativas ${i}`}
                    className="proyecto-imagen"
                />
                </div>
            );
        }
        return images;
    };

    const enviarCorreo = (event) => {
        event.preventDefault(); // Previene la recarga de la página.

        const nombre = event.target.nombre.value;
        const email = event.target.email.value;
        const mensaje = event.target.mensaje.value;

        // Cuerpo del correo
        const body = `
            Nombre: ${nombre}
            Email: ${email}
            Mensaje: ${mensaje}
        `;

        // Enlace mailto
        window.location.href = `mailto:sal_barquin@hotmail.com?subject=Contacto desde formulario&body=${encodeURIComponent(body)}`;
    };

    return (
        <div className="contenido">
            {/* Sección del video */}
            <div id="video-section" style={{ position: "relative", width: "100%", paddingTop: "56.25%" }}>
                <iframe
                    src="https://www.youtube.com/embed/2joTObNfKmc?si=kgNVihFQgfNQ9JQK"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "90%",
                        height: "100%"
                    }}
                ></iframe>
            </div>


            {/* Sección de proyectos */}
            <div className="proyectos">
                <h2 id="residencial">
                    RESIDENCIALES
                </h2>
                <div className="grid-general residenciales-grid">
                    {renderResidencialImages()}
                </div>
                <h2 id="comercial" >
                    COMERCIALES
                </h2>
                <div className="grid-general residenciales-grid">
                    {renderComercialImages()}
                </div>
                <div >
                    <h2 id="comercial" >
                        SOBRE ARQUITECTURA COMERCIAL
                    </h2>
                    <p className="texto_sa">
                        En <strong>Nextia + arqtect</strong>, contamos con una sólida
                        trayectoria en el ámbito de la arquitectura comercial. Desde 1987,
                        hemos diseñado y construido más de 100 locales comerciales, lo que
                        nos ha permitido adquirir una profunda experiencia y convertirnos
                        en expertos en este sector.
                        <br/>
                        Nuestra especialización en arquitectura comercial nos ha enseñado
                        a entender las necesidades específicas de cada negocio y a crear
                        espacios que no solo sean funcionales y atractivos, sino que
                        también potencien la experiencia del cliente y contribuyan al
                        éxito comercial. Trabajamos de la mano con nuestros clientes para
                        desarrollar soluciones personalizadas que reflejen la identidad de
                        su marca y se adapten al entorno competitivo actual.
                        <br/>A lo largo de más de tres décadas, hemos afrontado diversos
                        desafíos y nos hemos mantenido a la vanguardia de las tendencias y
                        tecnologías en diseño comercial. Nuestra dedicación y compromiso
                        con la excelencia nos permiten ofrecer proyectos de alta calidad
                        que cumplen con los más altos estándares estéticos y funcionales.
                    </p>
                </div>
                <h2 id="corporativo">
                    DESARROLLOS Y CORPORATIVO
                </h2>
                <div className="grid-general residenciales-grid">
                    {renderCorporativasImages()}
                </div>
            </div>

            {/* Sección "Nosotros" */}
            <div >
                <h2 id="nosotros" className="nosotros">
                    NOSOTROS
                </h2>
                <p className="texto">
                    En <strong>Nextia + Arqtect</strong>, somos un equipo
                    multidisciplinario fundado por tres profesionales que aportan
                    experiencia, pasión y visión a cada uno de nuestros proyectos.
                    <br/>
                    <strong>Arquitecto Salvador Barquín Noyola</strong>
                    <br/>
                    Con más de 35 años de trayectoria, el arquitecto Salvador Barquín
                    Noyola, egresado de la Universidad Nacional Autónoma de México,
                    enriquece nuestros proyectos desde aspectos técnicos hasta
                    funcionales y estéticos. Su vasta experiencia es un pilar
                    fundamental en el desarrollo de soluciones arquitectónicas
                    integrales.
                    <br/>
                    <strong>Arquitecto Damián Barquín Desormaux</strong>
                    <br/>
                    Graduado de la Universidad Autónoma Metropolitana, Damián Barquín
                    Desormaux es un apasionado de los temas y proyectos urbanos. Concibe
                    la arquitectura como el arte fundamental en la construcción de una
                    sociedad a través de su cultura, aportando una perspectiva
                    contemporánea y socialmente consciente a nuestro taller.
                    <br/>
                    <strong>
                        Maestro en Ciencias Económicas Gabriel Barquín Desormaux
                    </strong>
                    <br/>
                    El M.Sc Gabriel Barquín Desormaux juega un papel imprescindible en
                    el ámbito financiero, brindando un enfoque multidisciplinario que
                    garantiza la viabilidad y sostenibilidad de cada proyecto. Su
                    experiencia en economía añade una dimensión estratégica a nuestro
                    trabajo.
                    <br/>
                    Nuestra filosofía se centra en entender y materializar el gusto
                    cultural de cada uno de nuestros clientes. Creemos que la
                    arquitectura debe ser una extensión de la personalidad y los valores
                    de quienes la habitan. Por ello, colaboramos estrechamente con
                    nuestros clientes, permitiendo que su esencia se refleje en cada
                    proyecto en el que nos honran con su confianza.
                    <br/>
                    Nuestra arquitectura
                    es variada y adaptable, pero siempre mantiene el sello particular de
                    diseño que nos caracteriza. Nos comprometemos a crear espacios que
                    no solo cumplan con altos estándares estéticos y funcionales, sino
                    que también enriquezcan el tejido cultural y social de la comunidad
                    en México e internacionalmente.
                    <br/>
                </p>
            </div>
            <div >
                <h2 className="nosotros">
                    NUESTRO PROCESO DE DISEÑO
                </h2>
                <p className="texto">
                Nuestro proceso de diseño es transparente y colaborativo, y puedes seguirlo en nuestras redes sociales,
                    principalmente en TikTok, donde explicamos de forma didáctica cómo llegamos a los resultados. Comenzamos
                    con una fase de <strong>estudios preliminares</strong> utilizando la información que nos proporciona cada
                    cliente, con el fin de entender sus necesidades. Basándonos en ello, realizamos la <strong>conceptualización
                    creativa</strong> del proyecto a resolver.<br/>
                    Inicialmente, entregamos un layout (plano de distribución) en el cual acordamos con el cliente la
                    funcionalidad y distribución del espacio. Una vez aprobada esta etapa, procedemos a la visualización
                    del proyecto (Anteproyecto) , donde diseñamos cada espacio acordado cuidando todos los detalles para
                    lograr la máxima satisfacción de nuestros clientes. En esta fase, realizamos ajustes estéticos y de
                    diseño, permitiendo que el cliente visualice cómo se verán los espacios y podamos corregir cualquier
                    aspecto
                    que no sea de su agrado.<br/>
                    Finalmente, cuando el cliente está convencido de la funcionalidad y diseño de su proyecto, elaboramos
                    los planos ejecutivos. Estos son planos técnicos detallados para la ejecución de la obra, donde
                    especificamos todos los elementos necesarios para hacer realidad el proyecto.<br/>


                </p>

        </div>

    {/* Sección del formulario de contacto */
    }
            <div className="contenido">
                {/* ... otras secciones de tu contenido */}

                {/* Sección del formulario de contacto */}
                <div className="contacto">
                    <h2 id="contacto" >
                        CONTACTO
                    </h2>
                    <form className="formulario-contacto" onSubmit={enviarCorreo}>
                        <label htmlFor="nombre">Nombre:</label>
                        <input type="text" id="nombre" name="nombre" required/>

                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" required/>

                        <label htmlFor="mensaje">Mensaje:</label>
                        <textarea id="mensaje" name="mensaje" rows="4" required></textarea>

                        <button type="submit" className="boton-enviar">Enviar</button>
                    </form>
                </div>
            </div>
        </div>
    )
        ;
}

export default Contenido;
