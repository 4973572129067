import React from 'react';
import CustomNavbar from './componentes/Navbar/CustomNavbar';
import Contenido from './componentes/contenido/Contenido';

function App() {
    return (
        <div>
            <CustomNavbar />
            <div style={{marginTop: '80px', padding: '20px'}}>

                <div>
                    <CustomNavbar/>
                    <Contenido/>
                </div>
            </div>
        </div>
    );
}

export default App;
