import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp, faTiktok } from '@fortawesome/free-brands-svg-icons';
import './CustomNavbar.css';

function CustomNavbar() {
    const [expanded, setExpanded] = useState(false); // Estado para controlar el colapso

    const handleSelect = (eventKey) => {
        console.log(`Seleccionado: ${eventKey}`);
        setExpanded(false); // Cerrar el menú después de seleccionar
    };

    const handleToggle = () => {
        setExpanded(!expanded); // Alternar entre abierto y cerrado
    };

    return (
        <Navbar
            bg="white"
            expand="lg"
            fixed="top"
            expanded={expanded}
            style={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Navbar.Brand href="#video-section">
                <img
                    src="/assets/logo.png"
                    alt="Logo Nextia Arquitectura"
                    className="navbar-logo"
                />
            </Navbar.Brand>
            <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                onClick={handleToggle} // Controlar el estado del colapso
            />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto" onSelect={handleSelect}>
                    <NavDropdown title="Proyectos" id="nav-dropdown">
                        <NavDropdown.Item eventKey="1.1" href="#residencial">
                            Residencial
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="1.2" href="#comercial">
                            Comercial
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="1.3" href="#corporativo">
                            Desarrollos y corporativo
                        </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link eventKey="2" href="#nosotros">
                        Nosotros
                    </Nav.Link>
                    <Nav.Link eventKey="3" href="#contacto">
                        Contacto
                    </Nav.Link>
                </Nav>
                <Nav className="ms-auto social-icons">
                    <Nav.Link href="https://www.facebook.com/render.arqtect" target="_blank">
                        <FontAwesomeIcon icon={faFacebook} size="lg" />
                    </Nav.Link>
                    <Nav.Link
                        href="https://www.instagram.com/taller.arqtect/?fbclid=IwY2xjawG9e5VleHRuA2FlbQIxMAABHatC7I…"
                        target="_blank"
                    >
                        <FontAwesomeIcon icon={faInstagram} size="lg" />
                    </Nav.Link>
                    <Nav.Link href="https://wa.link/9t633k" target="_blank">
                        <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                    </Nav.Link>
                    <Nav.Link href="https://www.tiktok.com/@tallerarqtect1" target="_blank">
                        <FontAwesomeIcon icon={faTiktok} size="lg" />
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default CustomNavbar;
